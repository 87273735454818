<template>
    <div class="cd-el-select">
        <div class="p-4 flex justify-between items-center flex-wrap bg-white whitespace-no-wrap">
            <div class="flex items-center flex-wrap space-x-4">
                <span class="text-2xl">義檢</span>
                <span class="text-2xl text-danger" v-if="event">{{ event.name }}</span>
                <vs-dropdown vs-trigger-click class="text-primary cursor-pointer hover:font-semibold" v-if="events.length > 1">
                    <span>切換活動</span>
                    <vs-dropdown-menu class="w-64 whitespace-no-wrap overflow-hidden">
                        <vs-dropdown-item v-for="item in events" :key="item.id" @click="event_id = item.id">{{ item.eventtime + ' | ' + item.name }}</vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
                <span v-if="!event">{{ $t('freeclinic.freeclinicEventList') }}</span>
            </div>
            <div class="my-2 flex items-center flex-wrap space-x-4" v-if="event">
                <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-32 rounded" v-model="query" />
                <div>
                    <span class="text-primary cursor-pointer hover:font-semibold" @click="refresh">刷新</span>
                </div>
                <vs-button class="cd-button-2w rounded" @click="openForm">＋ 報名</vs-button>
            </div>
        </div>
        <vs-row class="py-2" v-if="event_id > 0 && measureData">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                <FreeClinicTable :event_id="event_id" :data="measureData"></FreeClinicTable>
            </vs-col>
        </vs-row>
        <vs-popup :active.sync="activeForm" title="義檢活動報名" @close="closeForm">
            <SuccessForm :joinSuccess="joinSuccess" :apptSuccess="apptSuccess"></SuccessForm>
            <JoinForm :data="event" :active="activeForm" v-if="event && !eventPatient"></JoinForm>
            <ApptForm :data="eventPatient" v-if="eventPatient && !apptSuccess"></ApptForm>
        </vs-popup>
    </div>
</template>

<script>
import FreeClinicTable from '@/components/FreeClinic/FreeClinicTable.vue'
import JoinForm from '@/components/FreeClinic/JoinForm.vue'
import ApptForm from '@/components/FreeClinic/ApptForm.vue'
import SuccessForm from '@/components/FreeClinic/SuccessForm.vue'
import { getEventMeasureData, getEvents } from '@/api/user'
export default {
    components: { FreeClinicTable, JoinForm, ApptForm, SuccessForm },
    data() {
        return {
            query: '',
            measureData: [],
            event_id: 0,
            events: [],
            activeForm: false,
            eventPatient: null,
            joinSuccess: false,
            apptSuccess: false,
        }
    },
    watch: {
        event_id(v) {
            this.getEventMeasureData()
            localStorage.setItem('selectedEvent', JSON.stringify(v))
        },
        query() {
            this.getEventMeasureData()
        },
    },
    computed: {
        did() {
            return this.$store.getters.did
        },
        event() {
            const data = _.find(this.events, (d) => d.id === this.event_id)
            return data ? data : null
        },
    },
    mounted() {
        // 取活動列表
        this.getEvents()
        setInterval(() => {
            this.refresh()
        }, 20000)
    },
    created() {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.createBus()
    },
    methods: {
        // 取活動清單
        getEvents() {
            const payload = {
                did: this.did,
            }
            getEvents(payload).then((res) => {
                this.events = res.data.data.data

                // 預設值
                const savedEvent = localStorage.getItem('selectedEvent')
                this.event_id = savedEvent ? JSON.parse(savedEvent) : this.events[0].id
            })
        },
        // 取義診活動所有會員的量測數據
        getEventMeasureData() {
            const payload = {
                event_id: this.event_id,
                query: this.query,
            }
            getEventMeasureData(payload).then((res) => {
                this.measureData = res.data.data.data
                if (this.$refs.monitor_list_table) {
                    this.$nextTick(() => {
                        this.$refs.monitor_list_table.doLayout()
                    })
                }
            })
        },
        //自動化更新數據
        fetchdeviceData() {
            axios.get(process.env.VUE_APP_API_URL_HIS + '/fetchdeviceData/' + this.event_id)
        },
        //刷新
        async refresh() {
            this.$vs.loading()
            try {
                await Promise.all([this.fetchdeviceData(), this.getEventMeasureData()])
            } finally {
                this.$vs.loading.close()
            }
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('upsertMesasureData', () => {
                this.getEventMeasureData()
            })
            this.$bus.$on('joinEvent', (data) => {
                this.getEventMeasureData()
                this.eventPatient = data
                this.joinSuccess = true
            })
            this.$bus.$on('apptEvent', () => {
                this.getEventMeasureData()
                this.joinSuccess = false
                this.apptSuccess = true
            })
            this.$bus.$on('close', () => {
                this.closeForm()
            })
            this.$bus.$on('makeEventAppt', (data) => {
                this.eventPatient = data
                this.openForm()
            })
        },
        openForm() {
            this.activeForm = true
        },
        closeForm() {
            this.eventPatient = null
            this.joinSuccess = false
            this.apptSuccess = false
            this.activeForm = false
        },
    },
    beforeDestroy() {
        this.$bus.$off('upsertMesasureData')
        this.$bus.$off('joinEvent')
        this.$bus.$off('apptEvent')
        this.$bus.$off('close')
    },
}
</script>
